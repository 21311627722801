<template>
  <section id="main">
    <div id="signature">
      <img src="../assets/logotipo_green.png" alt="Escala10" >
      <h1>Confirmação de Assinatura</h1>
      <p class='p' v-if="plan" v-html="plan.confirmacao"></p>
      <router-link to="/login">
        <b-button type="is-primary" size='is-large'>Voltar ao Login</b-button>
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Main',
  data() {
    return {
      plan: {}
    }
  },
  async created() {

    let loader = this.$loading.show()

    let userId = this.$route.params.auserid

    await this.setLogin(userId)

    this.$http.post(url + '/assinaturas/plans',
    {
      cpf: this.userInfo.cpf,
      email: this.userInfo.email
    })
    .catch(err => {
      console.log(err)
      loader.hide()
    })
    .then( response => {
      if (response) {
        let plans = response.data.data
        let [planSelect] = plans.filter(el => Number(el.codigo) === Number(this.userInfo.plano))
        if (planSelect) {
          this.plan = planSelect
        }
      }
      loader.hide() 
    })
  },
  computed: {
    ...mapGetters(['userInfo', 'userId'])
  },
  methods: {
    ...mapActions(['setLogin'])
  }
}
</script>

<style>
#main {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  text-align: center; 
  background-image: url('../assets/bg_escala10.png');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

#signature {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  color: white;
}

#signature img {
  width: auto;
  height: 50%;
}
#signature h1 {
  color: white;
  font-size: 1.5em;
  font-weight: bolder;
  margin-bottom: 0;
}
#signature .p {
  text-align: center;
}
</style>
